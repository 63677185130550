@font-face {
    font-family: 'TT Norms';
    src: local('TTNorms-Light'), url('./TTNorms-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'TT Norms';
    src: local('TTNorms-Regular'),
        url('./TTNorms-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'TT Norms';
    src: local('TTNorms-Medium'), url('./TTNorms-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'TT Norms';
    src: local('TTNorms-Bold'), url('./TTNorms-Bold.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'TT Norms';
    src: local('TTNorms-ExtraBold'),
        url('./TTNorms-ExtraBold.otf') format('opentype');
    font-weight: 700;
}
