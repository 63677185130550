@import url(../../../libs/shared/orbit-ui/assets/src/lib/fonts/fonts.css);

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only:focus {
    outline: 0.125rem solid black;
    clip: auto;
    width: auto;
    height: auto;
}

