@import url(../../../libs/shared/orbit-ui/assets/src/lib/fonts/fonts.css);
html {
    overflow: auto;
    font-feature-settings: 'liga' off;
}
body {
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-variant-ligatures: none;
    margin: 0;
}

div {
    box-sizing: border-box;
    font-variant-ligatures: none;
}

#contact-hsform-event label {
    display: inline-block;
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #303a47;
}

@media screen and (max-width: 768px) {
    #contact-hsform-event label,
    #contact-hsform-event label,
    #contact-hsform-event label {
        font-size: 10px;
    }
}

#contact-hsform-event .hbspot-event-form .hs-form {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    background: #e2f3fc;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    line-height: 31.2px;
    letter-spacing: 'normal';
    color: #303a47;
    padding: 0 2rem 0 2rem;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}
#contact-hsform-event .field ul.hs-error-msgs li label {
    padding: 0;
    text-transform: none;
    padding-left: 15px;
    letter-spacing: 0px;
    color: #ff3760;
}
#contact-hsform-event div.hs-main-font-element {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
}

#contact-hsform-event div.field {
    width: calc(50% - 0.5rem);
    position: relative;
}

#contact-hsform-event div.hs_message {
    width: calc(100% - 1.5rem) !important;
}
#contact-hsform-event .hs_message textarea {
    width: calc(100% - 0.5rem) !important;
}

#contact-hsform-event .hs-dependent-field {
    width: calc(50% - 0.5rem);
    position: relative;
}

@media screen and (max-width: 768px) {
    #contact-hsform-event div.field .hs-dependent-field {
        width: calc(100% - 0.5rem);
        position: relative;
    }
}

@media screen and (max-width: 768px) {
    #contact-hsform-event div.hs_message {
        width: calc(100% - 2rem) !important;
        position: relative;
    }
}

#contact-hsform-event div.field input {
    width: calc(100% - 2rem);
}

#contact-hsform-event div.hs_firstname input,
#contact-hsform-event div.hs_phone input {
    margin-right: 0.5rem;
}

#contact-hsform-event div.hs_email input {
    width: calc(100% - 2rem);
}
#contact-hsform-event div.hs_job_title_list select {
    width: calc(100% - 3rem);
}
@media screen and (max-width: 1023px) {
    #course-form .hbspot-event-form .hs_firstname,
    #course-form .hbspot-event-form .hs_lastname,
    #course-form .hbspot-event-form .hs_phone,
    #course-form .hbspot-event-form .hs_company {
        width: 100%;
    }
}

#contact-hsform-event .hs-main-font-element h1,
#contact-hsform-event .hs-main-font-element h2 {
    color: #0f1015;
    font-size: 32px;
    line-height: 40px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    text-align: center;
    padding-bottom: 48px;
    padding-top: 48px;
}
#contact-hsform-event
    div.hs-job_title_list.hs-fieldtype-select.field.hs-form-field,
div.hs_starfsheiti_annad.hs-starfsheiti_annad.hs-fieldtype-text.field.hs-form-field {
    width: 100% !important;
}

#contact-hsform-event .field input,
#contact-hsform-event .field textarea,
#contact-hsform-event .field select {
    outline: none;
    border: none;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
}

#contact-hsform-event .field .hs-form-radio input {
    /* width: 100%; */
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
}

#contact-hsform-event .field select {
    outline: none;
    background: white;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: '0.2px';
    color: #303a47;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
}
#contact-hsform-event .field input,
#contact-hsform-event .field textarea {
    background: #ffffff;
    border-radius: 100px;
    padding: 10px 24px;
    border: none;
    padding-right: 10px;
}

#contact-hsform-event .field select {
    background: #ffffff;
    border-radius: 100px;
    padding: 10px 0 10px 24px;
    width: calc(100% - 1.5rem);
    border: none;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

#contact-hsform-event .field ul.hs-error-msgs {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* #course-form .field ul.hs-error-msgs li label {
    padding: 0;
    text-transform: none;
    line-height: 1px;
    padding-left: 15px;
    letter-spacing: 0px;
    color: #ff3760;
} */

#contact-hsform-event .hs_error_rollup {
    display: none;
}

#contact-hsform-event .hs-richtext hs-main-font-element {
    display: none !important;
    flex-direction: column;
    /* align-items: center; */
}

#contact-hsform-event .field input.error {
    border: 1px solid #ff3760;
}

/* Submit */
#contact-hsform-event .legal-consent-container {
    /* width: calc(65% - 1rem); */
    font-size: 14px;
    /* padding-right: 1rem; */
    /* line-height: 22px; */
    padding-bottom: 40px;
}

@media screen and (max-width: 1023px) {
    #contact-hsform-event .legal-consent-container {
        width: 90%;
        margin: auto;
    }
}

#contact-hsform-event .hs-richtext {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-left: 15px;
}
@media screen and (max-width: 1023px) {
    #contact-hsform-event .hs-richtext {
        padding-left: 0px;
        padding-top: 20px;
    }
}

#contact-hsform-event .hs-submit {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}

#contact-hsform-event .hs-richtext a {
    color: #303a47;
    font-weight: 600;
    text-decoration: none !important;
}

@media screen and (max-width: 1023px) {
    #contact-hsform-event .hs-submit {
        width: 80%;
        padding-bottom: 40px;
        margin: auto;
    }
}
#contact-hsform-event .hs-submit .actions input {
    width: 150px;
    background: #0295f5;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    padding: 15px 24px;
    margin: 0px 16px;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 16px;
    color: #0f1015;
}

#contact-hsform-event .submitted-message {
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: '0.2px';
    color: #303a47;
    margin-bottom: 20px;
}

/* CHECKBOX EVENT */

#contact-hsform-event
    .hubspot-event-form
    ul.inputs-list
    li.hs-form-booleancheckbox {
    display: flex;
    flex-direction: column;
}
#contact-hsform-event ul.inputs-list.multi-container {
    list-style: none;
    min-width: 100%;
    margin: 0;
}

@media screen and (max-width: 768px) {
    #contact-hsform-event
        .hs-dependent-field
        .hs_mailing_category
        ul:first-of-type {
        display: flex;
        flex-direction: column !important;
    }
}
#contact-hsform-event .hs-form-booleancheckbox ul > :nth-child(2) {
    display: flex;
    flex-direction: column;
}
#contact-hsform-event .hs-form-booleancheckbox ul > :first-child {
    display: flex;
    flex-direction: row;
}
#contact-hsform-event .hs-form-booleancheckbox ul > :second {
    display: flex;
    flex-direction: row;
}
#contact-hsform-event ul.inputs-list.multi-container li.hs-form-checkbox {
    display: flex;
    flex-direction: column;
}
#contact-hsform-event
    ul.inputs-list.multi-container
    li.hs-form-booleancheckbox
    label {
    position: relative;
}
#contact-hsform-event div.field .hs-form-booleancheckbox-display {
    list-style: none;
    padding-left: 0;
    flex-direction: row;
    display: flex;
    text-align: left;
    height: 35px;
    flex-wrap: nowrap;
    width: 100%;
    /* height: 100%;
    width: 300px; */
}
#contact-hsform-event ul.inputs-list {
    list-style: none;
}
#contact-hsform-event
    div.hs_event_webinar_registration___receive_emails_for_other_events {
    width: 100%;
}
#contact-hsform-event ul.inputs-list li.hs-form-booleancheckbox label span {
    display: inline-block;
    list-style: none;
    margin-left: 20px; /* Width of the new radio select and any additional spacing on the left */
    margin-right: 16px; /* Additional spacing on the right */
    align-self: center;
    margin-bottom: 10px;
}

/*  the original checkbox */

#event_webinar_registration___receive_emails_for_other_events-eada31ce-ce77-433e-9d18-59b2c740284a {
    height: 19px;
    border-radius: 4px !important;
    max-height: 19px;
    min-height: 19px;
    left: 16px;
    opacity: 0;
    padding: 0;
    scale: 1;
    position: absolute;
    border: none !important;
    top: 0;
    width: 19px;
    max-width: 19px;
    min-width: 19px;
    z-index: 1;
}

ul.inputs-list li.hs-form-booleancheckbox label input:checked {
    opacity: 1 !important;
}

/* Add new booleancheckbox */
ul.inputs-list li.hs-form-booleancheckbox label span::before {
    height: 17px;
    border-radius: 4px !important;
    content: '';
    left: 19px;
    opacity: 1;
    padding: 0;
    position: absolute;
    border: none !important;
    top: 2px;
    width: 17px;
}
@media screen and (max-width: 1220px) {
    ul.inputs-list li.hs-form-booleancheckbox label span::before {
        left: 18px !important;
        top: 3px;
        width: 34px !important;
        height: 34px !important;
    }
}
ul.inputs-list li.hs-form-booleancheckbox label input:checked + span::before {
    opacity: 0 !important;
}

/* Style when focused */
#contact-hsform-event
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:focus
    + span::before {
    border: 2px solid #0f1015 !important;
}

/* blue */

#contact-hsform-event ul.inputs-list li.hs-form-booleancheckbox label input {
    accent-color: #0295f5;
}
#contact-hsform-event
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    border: 2px solid #80cafa !important;
}
/* Style when hover */
#contact-hsform-event
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input:hover
    + span::before {
    border: 2px solid #0295f5 !important;
}

/* -------------------- CONTACT FORM SLICE DEFAULT VARIATION STARTS HERE -------------------- */

#contact-hsform-slice .hs-error-msg {
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: unset;
    color: #303a47;
}

.hs_starfsheiti___nl.hs-starfsheiti___nl.hs-fieldtype-select.field.hs-form-field,
.hs_starfsheiti____l.hs-starfsheiti____l.hs-fieldtype-select.field.hs-form-field,
.hs-dependent-field .hs-fieldtype-select.field.hs-form-field {
    width: 100% !important;
}
#contact-hsform-slice label {
    display: inline-block;
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    /* text-transform: uppercase; */
    color: #303a47;
}
#contact-form-slice label {
    display: inline-block;
    padding-left: 16px;
    color: #303a47;
    font-family: TT Norms, Arial, Helvetica, sans-serif;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    /* text-transform: uppercase; */
    color: #303a47;
}

#contact-hsform-slice .hs-error-msg {
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: unset;
    color: #303a47;
}

@media screen and (max-width: 768px) {
    #contact-form-slice-slice label,
    #contact-form-slice-image label,
    #course-form label {
        font-size: 10px;
    }
}

#contact-form-slice .hbspot-contact-form .hs-form {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    /* background: #e2f3fc; */
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    line-height: 2rem;
    letter-spacing: 'normal';
    color: #303a47;
    padding: 0 2rem 0 2rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 769px) {
    #contact-form-slice .hbspot-contact-form .hs-form {
        flex-direction: column;
        padding: 0;
    }
}

#contact-form-slice div.hs-main-font-element {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
}
#contact-form-slice legend.hs-field-desc {
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #303a47;
}

#contact-form-slice div.field,
#contact-form-slice div.hs-dependent-field {
    width: calc(50% - 0.5rem);
    position: relative;
}
#contact-form-slice .hbspot-contact-form .hs_tegund_bunadar2 {
    width: 50% !important;
    position: relative;
}

#contact-form-slice .hbspot-contact-form .hs_tegund_verkbeidnar select {
    width: calc(100% - 4rem) !important;
    position: relative;
}
@media screen and (max-width: 1024px) {
    #contact-form-slice .hbspot-contact-form div.field,
    #contact-form-slice div.hs-dependent-field {
        width: calc(100% - 0.5rem) !important;
        position: relative;
    }
}

#contact-form-slice div.field input {
    width: calc(100% - 2rem);
    transition: border 0.2s ease-in-out;
}

#contact-form-slice div.field input:focus,
#contact-form-slice div.field select:focus,
#contact-form-slice div.field textarea:focus {
    border: 0.188rem solid #0f1015;
}

#contact-form-slice .hbspot-contact-form .hs_contact_form_category {
    width: calc(100% - 1rem) !important;
}

#contact-form-slice .hbspot-contact-form .hs_message {
    width: calc(50% - 0.5rem) !important;
}
#contact-form-slice .hbspot-contact-form .field textarea {
    width: calc(100% - 3rem) !important;
    resize: none;
}
#contact-form-slice div.hs_firstname input,
#contact-form-slice div.hs_phone input {
    margin-right: 0.5rem;
}

#contact-form-slice div.hs_email input {
    width: calc(100% - 2rem);
}
#contact-form-slice .hbspot-contact-form-label-filledGreen input,
.hbspot-contact-form-label-filledGreen select,
.hbspot-contact-form-label-filledGreen .hs-input {
    background-color: #dcf3f1 !important;
}
#contact-form-slice .hbspot-contact-formGreen .hs-richtext a {
    color: #06b7a7 !important;
}
#contact-form-slice .hbspot-contact-form-label-filledBlue input,
.hbspot-contact-form-label-filledBlue select,
.hbspot-contact-form-label-filledBlue .hs-input {
    background-color: #e2f3fc !important;
}
#contact-form-slice .hbspot-contact-formBlue .hs-richtext a {
    color: #0295f5 !important;
}
#contact-form-slice .hbspot-contact-form-label-filledOrange input,
.hbspot-contact-form-label-filledOrange select,
.hbspot-contact-form-label-filledOrange .hs-input {
    background-color: #ffe8cf !important;
}
#contact-form-slice .hbspot-contact-formOrange .hs-richtext a {
    color: #ff8300 !important;
}
#contact-form-slice .hbspot-contact-form-label-filledPink input,
.hbspot-contact-form-label-filledPink select,
.hbspot-contact-form-label-filledPink .hs-input {
    background-color: #fce4e7 !important;
}
#contact-form-slice .hbspot-contact-formPink .hs-richtext a {
    color: #ff3760 !important;
}
#contact-form-slice .hbspot-contact-form div.hs_job_title_list select {
    width: calc(100% - 4rem) !important;
}
#contact-form-slice .hbspot-contact-form .hs_message {
    width: calc(100%) !important;
}
#contact-form-slice .hbspot-contact-form .hs_message {
    width: calc(100%) !important;
}

@media screen and (max-width: 1023px) {
    #contact-form-slice .hbspot-contact-form .field select {
        width: calc(100% - 4rem) !important ;
    }
}

#contact-form-slice .hs-main-font-element h1,
#contact-form-slice .hs-main-font-element h2 {
    color: #0f1015;
    font-size: 32px;
    line-height: 40px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    text-align: center;
    padding-bottom: 48px;
    padding-top: 48px;
    display: none;
}
@media screen and (max-width: 1023px) {
    #contact-form-slice .hs-main-font-element h1,
    #contact-form-slice .hs-main-font-element h2 {
        padding-bottom: 8px;
        padding-top: 8px;
    }
}
/* @media screen and (max-width: 1023px) {
    #contact-form-slice .hbspot-contact-form .field input {
        width: (100%);
    }
} */

#contact-form-slice .hbspot-contact-form .field input,
#contact-form-slice .hbspot-contact-form .field textarea,
#contact-form-slice .hbspot-contact-form .field select {
    outline: none;
    border: none;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    width: calc(100% - 3rem);
}

#contact-form-slice .field .hs-form-radio input {
    /* width: 100%; */
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
}

#contact-form-slice .field select {
    outline: none;
    background: white;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: '0.2px';
    color: #303a47;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
}
#contact-form-slice .field input,
#contact-form-slice .field textarea {
    background: #ffffff;
    border-radius: 100px;
    padding: 10px 24px;
    border: none;
    padding-right: 10px;
    width: calc(100% - 3rem);
}

#contact-form-slice .field select {
    background: #ffffff;
    border-radius: 100px;
    padding: 10px 24px;
    border: none;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

#contact-form-slice .field ul.hs-error-msgs {
    list-style: none;
    padding: 0;
    margin: 0;
}
#contact-form-slice .field ul.hs-error-msgs li label {
    padding: 0;
    text-transform: none;
    line-height: 1px;
    padding-left: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #ff3760;
}

#contact-form-slice .hs_error_rollup {
    display: none;
}

#contact-form-slice .field input.error {
    border: 1px solid #ff3760;
}

/* Submit */
#contact-form-slice .legal-consent-container {
    width: calc(65% - 1rem);
    font-size: 14px;
    margin: auto;
    text-align: center;
    /* padding-right: 1rem; */
    /* line-height: 22px; */
}

@media screen and (max-width: 1023px) {
    #contact-form-slice .legal-consent-container {
        width: 90%;
        margin: auto;
    }
}

#contact-form-slice .hs-richtext hs-main-font-element {
    display: none !important;
    flex-direction: column;
    /* align-items: center; */
}

#contact-form-slice .hs-richtext {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-left: 15px;
}
@media screen and (max-width: 1023px) {
    #contact-form-slice .hs-richtext {
        padding-left: 0px;
        /* padding-top: 20px; */
    }
}

#contact-form-slice .hs-submit {
    width: 100%;
    display: flex;
    align-items: center;
    /* padding-bottom: 80px; */
}

#contact-form-slice .hs-richtext a {
    color: #303a47;
    font-weight: 600;
    text-decoration: none !important;
}

@media screen and (max-width: 1023px) {
    #contact-form-slice .hs-submit {
        width: 80%;
        justify-content: center;
        /* padding-bottom: 40px; */
        margin: auto;
    }
}

#contact-form-slice .hbspot-contact-formBlue .hs-submit .actions input {
    width: 100%;
    background: #0295f5 !important;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    padding: 15px 24px;
    margin: 0px 16px;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 16px;
    color: #0f1015;
}

#contact-form-slice .hbspot-contact-formOrange .hs-submit .actions input {
    width: 100%;
    background: #ff8300 !important;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    padding: 15px 24px;
    margin: 0px 16px;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 16px;
    color: #0f1015;
}
#contact-form-slice .hbspot-contact-formGreen .hs-submit .actions input {
    width: 100%;
    background: #06b7a7 !important;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    padding: 15px 24px;
    margin: 0px 16px;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 16px;
    color: #0f1015;
}
#contact-form-slice .hbspot-contact-formPink .hs-submit .actions input {
    width: 100%;
    background: #ff3760 !important;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    padding: 15px 24px;
    margin: 0px 16px;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 16px;
    color: #0f1015;
}

#contact-form-slice .submitted-message {
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: '0.2px';
    color: #303a47;
    margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 991px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 1240px) {
    html {
        font-size: 15px;
    }
}

@media screen and (min-width: 1440px) {
    html {
        font-size: 16px;
    }
}

/* -------------------- CONTACT FORM SLICE WITH IMAGE VARIATION STARTS HERE -------------------- */

#contact-form-slice .hbspot-contact-form-with-image .hs-error-msg {
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: unset;
    color: #303a47;
}
#contact-form-slice .hbspot-contact-form-with-image label {
    display: inline-block;
    padding-left: 16px !important;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    /* text-transform: uppercase; */
    color: #303a47;
}

#contact-hsform-slice .hbspot-contact-form-with-image .hs-error-msg {
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: unset;
    color: #303a47;
}

@media screen and (max-width: 768px) {
    #contact-form-slice-slice label,
    #contact-form-slice-image label,
    #course-form label {
        font-size: 10px;
    }
}

#contact-form-slice .hbspot-contact-form-with-image .hs-form {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    /* background: #e2f3fc; */
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    line-height: 31.2px;
    letter-spacing: 'normal';
    color: #303a47;
    padding: 0 2rem 0 2rem;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    #contact-form-slice .hbspot-contact-form-with-image .hs-form {
        flex-direction: column;
    }
}
#contact-form-slice
    .hs_tegund_verkefnis_vegna_serverks_i__jonustumidju.hs-tegund_verkefnis_vegna_serverks_i__jonustumidju.hs-fieldtype-select.field.hs-form-field {
    width: 100%;
}
#contact-form-slice div.hs-main-font-element {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
}
#contact-form-slice .hbspot-contact-form-with-image legend.hs-field-desc {
    padding-left: 16px;
    color: #3e3b42;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #303a47;
}

#contact-form-slice div.field,
#contact-form-slice div.hs-dependent-field {
    width: calc(50% - 0.5rem);
    position: relative;
}
#contact-form-slice .hs_job_title_list {
    width: 100% !important;
}

#contact-form-slice .hbspot-contact-form-with-image .hs_tegund_bunadar2 {
    width: 100% !important;
    position: relative;
}
#contact-form-slice .hbspot-contact-form-with-image .hs_message {
    width: 100% !important;
    position: relative;
}
#contact-form-slice .hbspot-contact-form-with-image .hs_tegund_verkbeidnar {
    width: 100% !important;
    position: relative;
}
@media screen and (max-width: 1024px) {
    #contact-form-slice div.field {
        width: calc(100% - 0.5rem) !important;
        position: relative;
    }
}

#contact-form-slice div.field input {
    width: calc(100% - 2rem);
}
#contact-form-slice div.hs-skilabod {
    width: calc(100%);
}

#contact-form-slice .hs_contact_form_category,
#contact-form-slice .hbspot-contact-form .hs_message {
    width: calc(100%) !important;
}
#contact-form-slice .field textarea {
    resize: none;
    width: calc(100% - 2rem) !important;
    padding: 10px 24px;
    gap: 10px;
    isolation: isolate;
    height: 104px;
    border-radius: 16px !important;
}
#contact-form-slice div.hs_firstname input,
#contact-form-slice div.hs_phone input {
    margin-right: 0.5rem;
}

#contact-form-slice div.hs_email input {
    width: calc(100% - 2rem);
}
#contact-form-slice div.hs_job_title_list select {
    width: calc(100% - 3rem);
}
#contact-form-slice div.hs_job_title_list select {
    width: calc(100% - 3rem) !important;
}
@media screen and (max-width: 1023px) {
    #course-form .hbspot-contact-form-with-image .hs_firstname,
    #course-form .hbspot-contact-form-with-image .hs_lastname,
    #course-form .hbspot-contact-form-with-image .hs_phone,
    #course-form .hbspot-contact-form-with-image .hs_company {
        width: 100%;
    }
}

#contact-form-slice .hs-main-font-element h1,
#contact-form-slice .hs-main-font-element h2 {
    color: #0f1015;
    font-size: 32px;
    line-height: 40px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    text-align: center;
    padding-bottom: 48px;
    padding-top: 48px;
    display: none;
}
@media screen and (max-width: 1023px) {
    #contact-form-slice .hs-main-font-element h1,
    #contact-form-slice .hs-main-font-element h2 {
        padding-bottom: 8px;
        padding-top: 8px;
    }
}

#contact-form-slice .field input,
#contact-form-slice .field textarea,
#contact-form-slice .field select {
    outline: none;
    border: none;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    width: calc(100% - 3rem);
}

#contact-form-slice .field .hs-form-radio input {
    /* width: 100%; */
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
}

#contact-form-slice .field select {
    outline: none;
    background: white;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: '0.2px';
    color: #303a47;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */
}
#contact-form-slice .field input,
#contact-form-slice .field textarea {
    background: #ffffff;
    border-radius: 100px;
    padding: 10px 24px;
    border: none;
    padding-right: 10px;
    width: calc(100% - 3rem);
}

#contact-form-slice .field select {
    background: #ffffff;
    border-radius: 100px;
    padding: 10px 24px;
    border: none;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

#contact-form-slice .field ul.hs-error-msgs {
    list-style: none;
    padding: 0;
    margin: 0;
}
#contact-form-slice
    .hbspot-contact-form-with-image
    .field
    ul.hs-error-msgs
    li
    label {
    padding: 0;
    text-transform: none;
    padding-left: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #ff3760;
}

#contact-form-slice .hs_error_rollup {
    display: none;
}

#contact-form-slice .field input.error {
    border: 1px solid #ff3760;
}

/* Submit */
#contact-form-slice .hbspot-contact-form-with-image .legal-consent-container {
    /* width: calc(65% - 1rem); */
    font-size: 14px;
    width: 100%;
    text-align: left;
    /* padding-right: 1rem; */
    /* line-height: 22px; */
}

@media screen and (max-width: 1023px) {
    #contact-form-slice .legal-consent-container {
        width: 90%;
        margin: auto;
    }
}

#contact-form-slice .hs-richtext hs-main-font-element {
    display: none !important;
    flex-direction: column;
    /* align-items: center; */
}

#contact-form-slice .hs-richtext {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-left: 15px;
}
@media screen and (max-width: 1023px) {
    #contact-form-slice .hbspot-contact-form-with-image .hs-richtext {
        padding-left: 0px;
        /* padding-top: 20px; */
    }
}

#contact-form-slice .hbspot-contact-form .hs-submit {
    width: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center;
    /* padding-bottom: 80px; */
}
#contact-form-slice .hbspot-contact-form-with-image.hs-submit {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 80px;
}

#contact-form-slice .hs-richtext a {
    color: #303a47;
    font-weight: 600;
    text-decoration: none !important;
}

@media screen and (max-width: 1023px) {
    #contact-form-slice .hs-submit {
        width: 80%;
        justify-content: center;
        /* padding-bottom: 40px; */
        margin: auto;
    }
}
#contact-form-slice .hs-submit .actions input {
    width: 150px;
    background: #0295f5;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    padding: 15px 24px;
    margin: 0px 16px;
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 16px;
    color: #0f1015;
}

#contact-form-slice .submitted-message {
    font-family: TT Norms, Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: '0.2px';
    color: #303a47;
    margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 991px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 1240px) {
    html {
        font-size: 15px;
    }
}

@media screen and (min-width: 1440px) {
    html {
        font-size: 16px;
    }
}

/* RADIO BUTTONS FOR #contact-form-slice */

#contact-form-slice div.field .hs_m_tir__u_i_sal_ input {
    list-style: none;
    padding-left: 0;
}
#contact-form-slice div.field .hs-form-radio {
    list-style: none;
    padding-left: 0;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
}
#contact-form-slice div.field .hs-form-radio-display {
    list-style: none;
    padding-left: 0;
    flex-direction: row;
    display: flex;
    text-align: left;
    height: 35px;
}
#contact-form-slice ul.inputs-list.multi-container {
    padding-left: 16px;
    list-style: none;
    min-width: 100%;
    margin: 0;
}
/* Custom Input */
#contact-form-slice ul.inputs-list.multi-container li.hs-form-radio label {
    position: relative;
}
#contact-form-slice ul.inputs-list.multi-container li.hs-form-radio label span {
    display: inline-block;
    margin-left: 40px; /* Width of the new radio select and any additional spacing on the left */
    margin-right: 16px; /* Additional spacing on the right */
}
/* Hide the original radio select */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    input {
    height: 24px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

/* Add new radio select */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    content: '';
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
    border-radius: 50%;
}
/* Style new checked item */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::after {
    content: '';
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    left: 7px;
    top: 7px;
    transition: opacity 0.2s ease-in-out;
}
/* Show when checked */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    input:checked
    + span::after {
    opacity: 1;
}
/* Style when focused */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-radio
    label:focus
    + span::after {
    outline: 3px solid transparent; /* For Windows high contrast mode. */
}
#contact-form-slice .hs-dependent-field .hs_mailing_category ul:first-of-type {
    display: flex;
    flex-direction: row;
}
#contact-form-slice ul {
    display: flex;
    flex-direction: column;
}

#contact-form-slice ul.inputs-list.multi-container li.hs-form-radio label span {
    padding-top: 4px;
    margin-left: 20px;
}

/* ORANGE */
#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #ffffff !important;
}
#contact-form-slice
    .hbspot-contact-form-label-filledOrange
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #ffe8cf !important;
}
#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-radio
    label:hover
    span::before {
    border: 2px solid #ff8300 !important;
}
#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-radio
    input:focus
    + span::after {
    box-shadow: 0 0 0 3px #ff8300 !important;
}

#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::after {
    border: 7px solid #ff8300 !important;
}
/* GREEN */
#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #ffffff !important;
}

#contact-form-slice
    .hbspot-contact-form-label-filledGreen
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #dcf3f1 !important;
}
#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-radio
    label:hover
    span::before {
    border: 2px solid #06b7a7 !important;
}
#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-radio
    input:focus
    + span::after {
    box-shadow: 0 0 0 3px #06b7a7 !important;
}

#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::after {
    border: 7px solid #06b7a7 !important;
}

/* Pink */
#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #ffffff !important;
}

#contact-form-slice
    .hbspot-contact-form-label-filledPink
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #fce4e7 !important;
}
#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-radio
    label:hover
    span::before {
    border: 2px solid #ff3760 !important;
}
#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-radio
    input:focus
    + span::after {
    box-shadow: 0 0 0 3px #ff3760 !important;
}

#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::after {
    border: 7px solid #ff3760 !important;
}

/* Blue */
#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #ffffff !important;
}

#contact-form-slice
    .hbspot-contact-form-label-filledBlue
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::before {
    border: 2px solid #e2f3fc !important;
}
#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-radio
    label:hover
    span::before {
    border: 2px solid #0295f5 !important;
}
#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-radio
    input:focus
    + span::after {
    box-shadow: 0 0 0 3px #0295f5 !important;
}

#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-radio
    label
    span::after {
    border: 7px solid #0295f5 !important;
}

/* CHECKBOX BUTTON CONTACT FORM */

/* Custom Input */
/* #contact-form-slice ul.inputs-list.multi-container li.hs-form-checkbox:first-child {
    margin: 16px 0;
    min-width: 100%;
} */

#contact-form-slice ul.inputs-list {
    list-style: none;
}
#contact-form-slice input[type='checkbox'] {
    height: 19px;
    border-radius: 4px !important;
    max-height: 19px;
    min-height: 19px;
    left: 16px;
    opacity: 0;
    padding: 0;
    scale: 1;
    position: absolute;
    border: none !important;
    top: 18%;
    width: 19px;
    max-width: 19px;
    min-width: 19px;
    z-index: 1;
}

#contact-form-slice
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input[type='checkbox']:checked {
    opacity: 1 !important;
}

/* Add new booleancheckbox */
#contact-form-slice
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    height: 17px !important;
    border-radius: 4px !important;
    content: '';
    left: 4% !important;
    top: 21% !important;
    opacity: 1;
    padding: 0;
    background-color: white;
    position: absolute;
    top: 2px;
    width: 17px !important;
}
/* @media screen and (max-width: 1220px) {
    ul.inputs-list li.hs-form-booleancheckbox label span::before {
        left: 18px !important;
        top: 3px;
        width: 34px !important;
        height: 34px !important;
    }
} */
#contact-form-slice
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input[type='checkbox']:checked
    + span::before {
    opacity: 0 !important;
}

/* Style when focused */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:focus
    + span::before {
    border: 2px solid #0f1015 !important;
}

/* blue */

#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    accent-color: #0295f5;
}

#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    border: 2px solid #80cafa !important;
}

/* orange */

#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input {
    accent-color: #ff8300;
    /* filter: invert(1); */
}
#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    border: 2px solid #ffb582 !important;
}
/* pink */

#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input {
    accent-color: #ff3760;
}
#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    border: 2px solid #ff9baf !important;
}
/* green */

#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input {
    accent-color: #06b7a7;
}
#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    span::before {
    border: 2px solid #82dbd3 !important;
}

/* Style when hover */
#contact-form-slice
    ul.inputs-list
    li.hs-form-booleancheckbox
    label
    input:hover
    + span::before {
    border: 2px solid #0295f5 !important;
}

#contact-form-slice ul.inputs-list.multi-container li.hs-form-checkbox {
    display: flex;
    flex-direction: column;
}
#contact-form-slice div.hs_mailing_category {
    width: 100% !important;
}
#contact-form-slice .hs_mailing_lists__webshop_ {
    padding-left: 32px;
}
@media screen and (max-width: 768px) {
    #contact-form-slice
        .hs-dependent-field
        .hs_mailing_category
        ul:first-of-type {
        display: flex;
        flex-direction: column !important;
    }
}
#contact-form-slice .hs-form-checkbox ul > :nth-child(2) {
    display: flex;
    flex-direction: column;
}
#contact-form-slice .hs-form-checkbox ul > :first-child {
    display: flex;
    flex-direction: row;
}
#contact-form-slice .hs-form-checkbox ul > :second {
    display: flex;
    flex-direction: row;
}
#contact-form-slice ul.inputs-list.multi-container li.hs-form-checkbox label {
    position: relative;
}
#contact-form-slice div.field .hs-form-checkbox-display {
    list-style: none;
    padding-left: 0;
    flex-direction: row;
    display: flex;
    text-align: left;
    height: 35px;
    flex-wrap: nowrap;
}

#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    span {
    display: inline-block;
    margin-left: 40px; /* Width of the new radio select and any additional spacing on the left */
    margin-right: 16px; /* Additional spacing on the right */
    align-self: center;
    margin-bottom: 10px;
}
/*  the original checkbox */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input {
    height: 19px;
    border-radius: 4px !important;
    max-height: 19px;
    min-height: 19px;
    left: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    border: none !important;
    top: 0;
    width: 19px;
    max-width: 19px;
    min-width: 19px;
    z-index: 1;
}
ul.inputs-list.multi-container li.hs-form-checkbox label input:checked {
    opacity: 1 !important;
}
/* Add new checkbox */
ul.inputs-list.multi-container li.hs-form-checkbox label span::before {
    height: 17px;
    border-radius: 4px !important;
    max-height: 17px;
    content: '';
    min-height: 17px;
    left: 3px;
    opacity: 1;
    padding: 0;
    position: absolute;
    border: none !important;

    top: 2px;
    width: 17px;
    max-width: 17px;
    min-width: 17px;
}
@media screen and (max-width: 768px) {
    ul.inputs-list.multi-container li.hs-form-checkbox label span::before {
        left: 1px !important;
    }
}
ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:checked
    + span::before {
    opacity: 0 !important;
}

/* Style when focused */
#contact-form-slice
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:focus
    + span::before {
    border: 2px solid #0f1015 !important;
}

/*  orange */

#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input {
    accent-color: #ff8300;
}
#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    span::before {
    border: 2px solid #ffb582 !important;
}
/* Style when hover */
#contact-form-slice
    .hbspot-contact-formOrange
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:hover
    + span::before {
    border: 2px solid #ff8300 !important;
}

/* pink */

#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input {
    accent-color: #ff3760;
}

#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    span::before {
    border: 2px solid #ff9baf !important;
}
/* Style when hover */
#contact-form-slice
    .hbspot-contact-formPink
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:hover
    + span::before {
    border: 2px solid #ff3760 !important;
}

/* blue */

#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input {
    accent-color: #0295f5;
}
#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    span::before {
    border: 2px solid #80cafa !important;
}
/* Style when hover */
#contact-form-slice
    .hbspot-contact-formBlue
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:hover
    + span::before {
    border: 2px solid #0295f5 !important;
}

/* teal */

#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input {
    accent-color: #06b7a7;
}
#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    span::before {
    border: 2px solid #82dbd3 !important;
}
/* Style when hover */
#contact-form-slice
    .hbspot-contact-formGreen
    ul.inputs-list.multi-container
    li.hs-form-checkbox
    label
    input:hover
    + span::before {
    border: 2px solid #06b7a7 !important;
}

/* COOKIE CONSENT */

div.ch2-dialog-actions {
    display: grid !important;
    margin-top: 40px;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 20px;
}

/* Leyfa allar vafrakökur button */
.ch2-theme-default .ch2-dialog-actions button.ch2-btn-text-sm {
    background-color: #0295f5;
    color: #0f1015;
    border-radius: 30px;
    font-family: 'TT norms';
    text-transform: unset !important;
    font-size: 16px !important;
    padding: 16px 32px;
    font-weight: 600;
}

/* Stillingar á vafrakökum button */
.ch2-theme-default .ch2-dialog-actions button.ch2-btn-text-xs {
    background-color: transparent;
    color: #0f1015;
    border: 2px solid #0f1015;
    border-radius: 30px;
    font-family: 'TT norms';
    background-image: url('../public/images/settings-cookie-consent.png');
    background-repeat: no-repeat;
    background-position: 90% 50%;
    padding-right: 50px;
    text-transform: unset !important;
    font-size: 16px !important;
    padding: 16px 50px 16px 32px;
    font-weight: 600;
}

.ch2-theme-default .ch2-dialog-content {
    font-family: 'TT norms';
}

.ch2-theme-default .ch2-dialog p:last-child {
    font-size: 14px;
    font-weight: 400;
    color: #0f1015;
}

.ch2-theme-default .ch2-dialog p:first-child {
    margin-bottom: 24px !important;
    font-size: 24px;
    font-weight: 600 !important;
}

.ch2-theme-default .ch2-dialog.ch2-dialog-center.ch2-visible {
    background-image: url('../public/images/cookie-consent.png');
    position: absolute;
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: right;
    background-origin: content-box;
}

@media screen and (max-width: 1001px) {
    .ch2-theme-default .ch2-dialog.ch2-dialog-center.ch2-visible {
        background-position: top;
        background-size: 130px;
    }
}
@media screen and (max-width: 1001px) {
    .ch2-theme-default .ch2-dialog p:first-child {
        margin-top: 150px;
    }
}
@media screen and (max-width: 1001px) {
    div.ch2-dialog-actions {
        grid-template-columns: repeat(1, min-content) !important ;
        grid-template-rows: repeat(2, min-content);
        justify-content: center;
        margin-top: 20px !important;
    }
}
@media screen and (max-width: 650px) {
    div.ch2-dialog-actions {
        grid-template-columns: repeat(1, min-content) !important ;
        grid-template-rows: repeat(2, min-content);
        justify-content: center;
        display: block !important;
        margin-top: 20px !important;
    }
}
@media screen and (max-width: 650px) {
    .ch2-theme-default .ch2-dialog-actions button.ch2-btn-text-xs {
        padding: 12px 18px 12px 18px;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 650px) {
    .ch2-theme-default .ch2-dialog-actions button.ch2-btn-text-sm {
        padding: 12px 18px 12px 22px;
        font-size: 14px !important;
    }
}

.ch2-theme-default .ch2-dialog.ch2-dialog-center.ch2-visible {
    display: flex;
    flex-direction: column;
}
.ch2-style-light .ch2-settings-scan .ch2-settings-tabs ul {
    border-bottom: none !important;
    margin-bottom: 20px;
}

.ch2-style-light .ch2-settings-scan .ch2-settings-tabs ul li.active a {
    background: #d8dce1 !important;
    border-radius: 40px;
    border: none !important;
    font-family: 'TT norms';
    color: #0f1015;
    font-weight: 600;
    margin-right: 8px;
}

.ch2-style-light .ch2-settings-scan .ch2-settings-tabs ul li a {
    border-radius: 40px;
    background: #f8fafb !important;
    border: none !important;
    font-family: 'TT norms';
    color: #a4afbe;
    font-weight: 600;
    margin-right: 8px;
}

.ch2-style-light .ch2-settings-content dd,
.ch2-style-light .ch2-settings-content dt,
.ch2-style-light .ch2-settings-content p {
    color: #0f1015 !important;
    font-family: 'TT norms';
}

.ch2-settings-content
    .ch2-settings-tab-container
    > button.ch2-btn.ch2-btn-secondary.ch2-allow-all-btn {
    background-color: #0295f5;
    color: #0f1015;
    border-radius: 30px;
    font-family: 'TT norms';
    text-transform: unset !important;
    font-size: 16px !important;
    padding: 16px 32px;
    font-weight: 600;
}

.ch2-settings-content
    .ch2-settings-tab-container
    > button.ch2-btn.ch2-btn-secondary.ch2-deny-all-btn {
    background-color: transparent;
    color: #0f1015;
    border: 2px solid #0f1015;
    border-radius: 30px;
    font-family: 'TT norms';
    text-transform: unset !important;
    font-size: 16px !important;
    padding: 16px 32px;
    font-weight: 600;
}

@media screen and (max-width: 650px) {
    .ch2-settings-content
        .ch2-settings-tab-container
        > button.ch2-btn.ch2-btn-secondary.ch2-deny-all-btn,
    .ch2-settings-content
        .ch2-settings-tab-container
        > button.ch2-btn.ch2-btn-secondary.ch2-allow-all-btn {
        padding: 12px 18px 12px 18px;
        font-size: 14px !important;
    }
}

.ch2-style-light .ch2-switch input:checked + label,
.ch2-style-dark .ch2-switch input:checked + label {
    background: #0295f5 !important;
}

.ch2-switch input:disabled + label:before {
    background: #ffffff !important ;
}

.ch2-style-light .ch2-btn-primary,
.ch2-style-dark .ch2-btn-primary {
    background-color: #0295f5 !important;
    color: #0f1015 !important;
    border-radius: 30px;
    font-family: 'TT norms';
    text-transform: unset !important;
    font-size: 16px !important;
    padding: 16px 32px;
    font-weight: 600;
}

.ch2-style-light .ch2-settings-content dd,
.ch2-style-light .ch2-settings-content dt,
.ch2-style-light .ch2-settings-content #ch2-0-title,
.ch2-style-light .ch2-settings-content #ch2-1-title,
.ch2-style-light .ch2-settings-content #ch2-2-title,
.ch2-style-light .ch2-settings-content #ch2-3-title,
.ch2-style-light .ch2-settings-content #ch2-4-title {
    margin-bottom: 16px !important;
}

.ch2-settings-header p strong {
    font-size: 24px;
    font-family: 'TT norms';
    font-weight: 600 !important;
}

.ch2-style-light .ch2-settings {
    width: 80%;
}

@media screen and (max-width: 800px) {
    .ch2-style-light .ch2-settings {
        width: auto !important;
    }
}

.ch2-settings-declaration {
    font-family: 'TT norms';
}

.ch2-style-light .ch2-btn-primary:hover,
.ch2-style-dark .ch2-btn-primary:hover {
    box-shadow: none !important;
    background: #80cafa !important;
}

.ch2-settings-content
    .ch2-settings-tab-container
    > button.ch2-btn.ch2-btn-secondary.ch2-allow-all-btn:hover {
    box-shadow: none !important;
    background: #80cafa !important;
}
.ch2-style-light.ch2-icon a {
    fill: none !important;
    background-size: 25px;
    /* background: url('../public/images/cookie-consent.png') !important; */
    background-position: 55% 50%;
    background-image: url('../public/images/cookie-consent.png');
    background-repeat: no-repeat;
    border: none !important;
    z-index: 5;
    filter: drop-shadow(0px 10.9333px 19.1333px rgba(21, 58, 152, 0.16));
}
.ch2-style-light.ch2-icon a svg {
    fill: none !important;
    background-size: 25px;
    opacity: 0;
    background: url('../public/images/cookie-consent.png') !important;
    background-position: 60% 60%;
    background-image: url('../public/images/cookie-consent.png');
    background-repeat: no-repeat;
    border: none !important;
    filter: drop-shadow(0px 10.9333px 19.1333px rgba(21, 58, 152, 0.16));
}
.ch2-style-light.ch2-icon a:hover {
    background-image: url('../public/images/cookie-consent.png') !important;
    background-repeat: no-repeat !important;
    background-size: 25px !important;
    background-position: 4% 50% !important;
    font-family: 'TT norms';
}

.ch2-icon.ch2-visible {
    background: #ffffff;
    border-radius: 40px;
    border: none !important;
}

.ch2-style-light.ch2-icon a:hover svg {
    border: none !important;
}

.ch2-style-light .ch2-settings-content .ch2-settings-tab-container {
    background-image: url('../public/images/cookie-consent.png');
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: top right;
    /* margin-right: 50px; */
}

.ch2-style-light .ch2-settings-content dd,
.ch2-style-light .ch2-settings-content dt,
.ch2-style-light .ch2-settings-content #ch2-settings-description {
    width: 70%;
}

.ch2-style-light
    .ch2-settings-content
    .ch2-settings-tab-container
    .ch2-settings-declaration
    > p {
    width: 70%;
}
@media screen and (max-width: 800px) {
    .ch2-style-light
        .ch2-settings-content
        .ch2-settings-tab-container
        .ch2-settings-declaration
        > p {
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        .ch2-style-light .ch2-settings-content .ch2-settings-tab-container {
            background-image: none !important;
        }
    }
}

@media screen and (max-width: 800px) {
    .ch2-style-light .ch2-settings-content dd,
    .ch2-style-light .ch2-settings-content dt,
    .ch2-style-light .ch2-settings-content #ch2-settings-description {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .ch2-style-light .ch2-settings-header p {
        height: 100%;
        background-image: url('../public/images/cookie-consent.png');
        background-size: 150px;
        background-repeat: no-repeat;
        background-attachment: fixed !important;
        background-position-y: 20px;
        background-position-x: center;
    }
}
@media screen and (max-width: 800px) {
    .ch2-style-light .ch2-settings-header {
        min-height: 220px;
        position: relative;
    }
}
@media screen and (max-width: 800px) {
    .ch2-settings-header p strong {
        /* margin-top: 150px; */
        position: absolute;
        bottom: 0;
    }
}

@media screen and (max-width: 992px) {
    .ch2-icon,
    #hubspot-messages-iframe-container {
        z-index: 99 !important;
    }
}

/* Björg Chatbox */

.gOIIGo:focus {
    outline: 2px black solid;
}
